import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useEffect, useState } from "react";
import bgNatale from "./img/bg-natale.png";
import Game from "./game/Game";

// https://github.com/lhz516/react-h5-audio-player
// https://github.com/LarsBergqvist/react-memory-simple

const Player = ({ update, updateIndex, playlist, color, ...props }) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [currentTrack, setCurrentTrack] = useState(props.currentTrack ?? 0);
  const manageTimeUpdate = (d) => {
    setCurrentTime(d.currentTarget.currentTime);
  }

  const handleClickNext = () => {
    // console.log('click next')
    setCurrentTrack((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    );
    update(0);
  };

  const handleClickPrev = () => {
    // console.log('click next')
    setCurrentTrack((currentTrack) =>
      currentTrack > playlist.length - 1 ? currentTrack - 1 : 0
    );
    update(0);
  };

  const handleEnd = () => {
    // console.log('end')
    setCurrentTrack((currentTrack) =>
      currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
    );
  }

  useEffect(() => {
    if (currentTrack === 0)
      update(currentTime);
  }, [currentTime])

  useEffect(() => {
    updateIndex(currentTrack + 1)
  }, [currentTrack])

  return (
    <AudioPlayer
      {...props}
      autoPlay={true}
      src={playlist.length > 1 ? playlist[currentTrack].src : playlist.src}
      // showJumpControls={false}
      listenInterval={500}
      // onPlay={e => console.log("onPlay")}
      onListen={e => manageTimeUpdate(e)}
      onClickNext={handleClickNext}
      onClickPrevious={handleClickPrev}
      onEnded={handleEnd}
      header={<div className='flex flex-row justify-center '><p className='text-base font-bold'>Ascolta la nostra playlist</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 stroke-2">
        <path strokeLinecap="round" strokeLinejoin="round" d="m11.99 16.5 3.75 3.75m0 0 3.75-3.75m-3.75 3.75V3.75H4.49" />
      </svg>
      </div>}
    />
  )
};

const timeToSeconds = (time) => {
  const [minutes, seconds] = time.split(":").map(Number);
  return minutes * 60 + seconds;
};

const findBoundingKeys = (data, targetTime) => {
  // const targetSeconds = timeToSeconds(targetTime);
  const targetSeconds = parseInt(targetTime);

  // Ottieni le chiavi ordinate in secondi
  const sortedKeys = Object.keys(data).sort((a, b) => timeToSeconds(a) - timeToSeconds(b));

  let lowerBound = null, upperBound = null;

  // Trova gli intervalli
  for (let i = 0; i < sortedKeys.length; i++) {
    const currentSeconds = timeToSeconds(sortedKeys[i]);

    if (currentSeconds <= targetSeconds) {
      lowerBound = sortedKeys[i];
    }

    if (currentSeconds > targetSeconds) {
      upperBound = sortedKeys[i];
      break;
    }
  }

  return { lowerBound, upperBound };
};

function App() {
  const [versione, setVersione] = useState('cover');
  const [testo, setTesto] = useState(null);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [currentText, setCurrentText] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [touchStart, setTouchStart] = useState(null)
  const [touchEnd, setTouchEnd] = useState(null)
  const minSwipeDistance = 150;

  const datiVersione = {
    imgBg: bgNatale,
    titolo: "Buon Natale",
    playlist: [
      { src: 'music/00.mp3' },
      { src: 'music/01.mp3' },
      { src: 'music/02.mp3' },
      { src: 'music/03.mp3' },
      { src: 'music/04.mp3' },
      { src: 'music/05.mp3' },
      { src: 'music/06.mp3' },
      { src: 'music/07.mp3' },
      { src: 'music/08.mp3' },
      { src: 'music/09.mp3' }
    ],
    colore1: "#ffffff",
    coloreBg: "bg-[#D92626]",
    colore2: "#D92626",
    coloreTesto: "text-[#fff]",
    coloreTesto2: "text-[#D92626]",
  };

  // useEffect(() => {
  //   fetch(`${process.env.PUBLIC_URL}/${datiVersione.testo}`)
  //     .then((r) => r.json())
  //     .then((data) => {
  //       setTesto(data);
  //     })
  // }, [])

  useEffect(() => {
    if (currentTime > 0 && testo) {
      // console.log(currentTime);
      const { lowerBound, upperBound } = findBoundingKeys(testo, currentTime);
      // console.log(lowerBound, upperBound, testo[lowerBound]);
      if (lowerBound) setCurrentText(testo[lowerBound])
    } else {
      setCurrentText("")
    }
  }, [currentTime])

  // useEffect(() => {
  // }, [currentIndex])

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
    // add your conditional logic here
  }

  return (
    <div
      className={`min-h-screen bg-cover bg-no-repeat bg-center overflow-hidden w-full`}
      style={{ backgroundImage: `url(${datiVersione.imgBg})` }}
    >
      <header className={`static sm:static top-0 w-full text-center px-3 py-2 text-2xl ${datiVersione.coloreBg}`}>
        <h1 className={`pb-0 mt-2 font-gineva ${datiVersione.coloreTesto}`}>{datiVersione.titolo}</h1>
        <h1 className={`pb-0 mt-2 font-gineva ${datiVersione.coloreTesto}`}>da Sound of Web</h1>
      </header>
      <main className='static sm:static flex justify-center px-5 pt-5 pb-[170px] sm:pb-[120px] text-4xl w-full h-full overflow-hidden'>
        <div className='font-bold flex flex-col gap-10 justify-center grow h-full w-full items-center max-w-screen-sm'>
          {versione === 'cover' ?
            <div className='rounded-xl shadow-xl shadow-black overflow-hidden max-w-[80%]' onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
              <img src={require(`./img/covers/${currentIndex <= 9 ? `0${currentIndex}` : currentIndex}.jpg`)} />
            </div>
            :
            <div className='bg-white/90 rounded-xl border border-black/20 w-full'>
              <Game datiVersione={datiVersione} />
            </div>
          }
          <div className=''>
            <button className={`rounded-lg shadow-xl shadow-gray-700 ${datiVersione.coloreBg} ${datiVersione.coloreTesto} text-base px-4 py-2`} onClick={() => setVersione(versione === 'cover' ? 'game' : 'cover')}>
              {versione === 'cover' ?
              <div className='flex flex-row gap-5'><p>Giochiamo un po'?</p><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 stroke-2"><path strokeLinecap="round" strokeLinejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" /></svg></div>
              :
              <div className='flex flex-row gap-5'><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 stroke-2"><path strokeLinecap="round" strokeLinejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" /></svg><p>Torna indietro</p></div>
              }
            </button>
          </div>

        </div>
      </main>
      <footer className='fixed inset-x-0 bottom-0'>
        <Player update={setCurrentTime} updateIndex={setCurrentIndex} playlist={datiVersione.playlist} showSkipControls={true} color={datiVersione.coloreBg} />
      </footer>
    </div>
  );
}

export default App;
